/* eslint-disable */

(function () {
  // Defining locale
  Date.shortMonths = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
  Date.longMonths = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  Date.shortDays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  Date.longDays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  // Defining patterns
  const replaceChars = {
    // Day
    d() { const d = this.getDate(); return (d < 10 ? '0' : '') + d; },
    D() { return Date.shortDays[this.getDay()]; },
    j() { return this.getDate(); },
    l() { return Date.longDays[this.getDay()]; },
    N() { const N = this.getDay(); return (N === 0 ? 7 : N); },
    S() { const S = this.getDate(); return (S % 10 === 1 && S !== 11 ? 'st' : (S % 10 === 2 && S !== 12 ? 'nd' : (S % 10 === 3 && S !== 13 ? 'rd' : 'th'))); },
    w() { return this.getDay(); },
    z() { const d = new Date(this.getFullYear(), 0, 1); return Math.ceil((this - d) / 86400000); },
    // Week
    W() {
      return new Date(this).getWeek();
    },
    // Month
    F() { return Date.longMonths[this.getMonth()]; },
    m() { const m = this.getMonth(); return (m < 9 ? '0' : '') + (m + 1); },
    M() { return Date.shortMonths[this.getMonth()]; },
    n() { return this.getMonth() + 1; },
    t() {
      let year = this.getFullYear();
      let nextMonth = this.getMonth() + 1;
      if (nextMonth === 12) {
        year = year++;
        nextMonth = 0;
      }
      return new Date(year, nextMonth, 0).getDate();
    },
    // Year
    L() { const L = this.getFullYear(); return (L % 400 === 0 || (L % 100 !== 0 && L % 4 === 0)); },
    o() { const d = new Date(this.valueOf()); d.setDate(d.getDate() - ((this.getDay() + 6) % 7) + 3); return d.getFullYear(); },
    Y() { return this.getFullYear(); },
    y() { return (`${this.getFullYear()}`).substr(2); },
    // Time
    a() { return this.getHours() < 12 ? 'am' : 'pm'; },
    A() { return this.getHours() < 12 ? 'AM' : 'PM'; },
    B() { return Math.floor((((this.getUTCHours() + 1) % 24) + this.getUTCMinutes() / 60 + this.getUTCSeconds() / 3600) * 1000 / 24); },
    g() { return this.getHours() % 12 || 12; },
    G() { return this.getHours(); },
    h() { const h = this.getHours(); return ((h % 12 || 12) < 10 ? '0' : '') + (h % 12 || 12); },
    H() { const H = this.getHours(); return (H < 10 ? '0' : '') + H; },
    i() { const i = this.getMinutes(); return (i < 10 ? '0' : '') + i; },
    s() { const s = this.getSeconds(); return (s < 10 ? '0' : '') + s; },
    v() { const v = this.getMilliseconds(); return (v < 10 ? '00' : (v < 100 ? '0' : '')) + v; },
    // Timezone
    e() { return Intl.DateTimeFormat().resolvedOptions().timeZone; },
    I() {
      let DST = null;
      for (let i = 0; i < 12; ++i) {
        const d = new Date(this.getFullYear(), i, 1);
        const offset = d.getTimezoneOffset();

        if (DST === null) DST = offset;
        else if (offset < DST) { DST = offset; break; } else if (offset > DST) break;
      }
      return (this.getTimezoneOffset() === DST) | 0;
    },
    O() { const O = this.getTimezoneOffset(); return (-O < 0 ? '-' : '+') + (Math.abs(O / 60) < 10 ? '0' : '') + Math.floor(Math.abs(O / 60)) + (Math.abs(O % 60) === 0 ? '00' : ((Math.abs(O % 60) < 10 ? '0' : '')) + (Math.abs(O % 60))); },
    P() { const P = this.getTimezoneOffset(); return `${(-P < 0 ? '-' : '+') + (Math.abs(P / 60) < 10 ? '0' : '') + Math.floor(Math.abs(P / 60))}:${Math.abs(P % 60) === 0 ? '00' : ((Math.abs(P % 60) < 10 ? '0' : '')) + (Math.abs(P % 60))}`; },
    T() { const tz = this.toLocaleTimeString(navigator.language, { timeZoneName: 'short' }).split(' '); return tz[tz.length - 1]; },
    Z() { return -this.getTimezoneOffset() * 60; },
    // Full Date/Time
    c() { return this.format('Y-m-d\\TH:i:sP'); },
    r() { return this.toString(); },
    U() { return Math.floor(this.getTime() / 1000); },
  };

  // Simulates PHP's date function
  Date.prototype.format = function (format, removeOffset = false) {
    let date = this;

    if (removeOffset) {
      date = date.subMinutes(Math.abs(date.getTimezoneOffset()));
    }

    return format.replace(/(\\?)(.)/g, (_, esc, chr) => ((esc === '' && replaceChars[chr]) ? replaceChars[chr].call(date) : chr));
  };

  Date.prototype.startOfDay = function () {
    const d = new Date(this);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
  };

  Date.prototype.startOfWeek = function () {
    let d = new Date(this);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    d = new Date(d.setDate(diff));
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
  };

  Date.prototype.startOfMonth = function () {
    const d = new Date(this);
    return new Date(d.getFullYear(), d.getMonth(), 1, 0, 0, 0, 0);
  };

  Date.prototype.startOfYear = function () {
    const d = new Date(this);
    return new Date(d.getFullYear(), 0, 1, 0, 0, 0, 0);
  };

  Date.prototype.endOfDay = function () {
    const d = new Date(this);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999);
  };

  Date.prototype.endOfWeek = function () {
    const d = new Date(this).startOfWeek();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() + 6, 23, 59, 59, 999);
  };

  Date.prototype.endOfMonth = function () {
    const d = new Date(this);
    return new Date(d.getFullYear(), d.getMonth() + 1, 0, 23, 59, 59, 999);
  };

  Date.prototype.endOfYear = function () {
    const d = new Date(this);
    return new Date(d.getFullYear() + 1, 0, 0, 23, 59, 59, 999);
  };

  Date.prototype.getWeek = function () {
    const date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
            - 3 + (week1.getDay() + 6) % 7) / 7);
  };

  Date.prototype.getWeekYear = function () {
    const date = new Date(this.getTime());
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
  };

  Date.prototype.treatAsUTC = function () {
    const date = new Date(this);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  };

  Date.prototype.diffInYears = function (diffDate) {
    return Math.abs(diffDate.getFullYear() - this.getFullYear());
  };

  Date.prototype.diffInMonths = function (diffDate) {
    let months;
    months = (diffDate.getFullYear() - this.getFullYear()) * 12;
    months -= this.getMonth();
    months += diffDate.getMonth();
    return Math.abs(months <= 0 ? 0 : months);
  };

  Date.prototype.diffInWeeks = function (diffDate) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    return Math.floor(Math.abs((this.treatAsUTC() - diffDate.treatAsUTC()) / millisecondsPerDay / 7));
  };

  Date.prototype.diffInDays = function (diffDate) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    return Math.floor(Math.abs((this.treatAsUTC() - diffDate.treatAsUTC()) / millisecondsPerDay));
  };

  Date.prototype.diffInHours = function (diffDate) {
    const millisecondsPerDay = 60 * 60 * 1000;

    return Math.floor(Math.abs((this.treatAsUTC() - diffDate.treatAsUTC()) / millisecondsPerDay));
  };

  Date.prototype.diffInMinutes = function (diffDate) {
    const millisecondsPerDay = 60 * 1000;

    return Math.floor(Math.abs((this.treatAsUTC() - diffDate.treatAsUTC()) / millisecondsPerDay));
  };

  Date.prototype.diffInSeconds = function (diffDate, floor = true) {
    const millisecondsPerDay = 1000;
    let diff = Math.abs((this.treatAsUTC() - diffDate.treatAsUTC()) / millisecondsPerDay);

    if (floor) {
      diff = Math.floor(diff);
    }

    return diff;
  };

  Date.prototype.diffInMilliSeconds = function (diffDate, floor = true) {
    let diff = Math.abs((this.treatAsUTC() - diffDate.treatAsUTC()));

    if (floor) {
      diff = Math.floor(diff);
    }

    return diff;
  };

  Date.prototype.addMinutes = function (minutes = 1) {
    const date = new Date(this);
    date.setMinutes(date.getMinutes() + minutes);
    return date;
  };

  Date.prototype.addHours = function (hours = 1) {
    const date = new Date(this);
    date.setHours(date.getHours() + hours);
    return date;
  };

  Date.prototype.addDays = function (days = 1) {
    const date = new Date(this);
    date.setDate(date.getDate() + days);
    return date;
  };

  Date.prototype.addWeeks = function (weeks = 1) {
    const date = new Date(this);
    date.setDate(date.getDate() + (weeks * 7));
    return date;
  };

  Date.prototype.addMonths = function (months = 1) {
    const date = new Date(this);
    date.setMonth(date.getMonth() + months);
    return date;
  };

  Date.prototype.addYears = function (years = 1) {
    const date = new Date(this);
    date.setFullYear(date.getFullYear() + years);
    return date;
  };

  Date.prototype.subMinutes = function (minutes = 1) {
    const date = new Date(this);
    date.setMinutes(date.getMinutes() - minutes);
    return date;
  };

  Date.prototype.subHours = function (hours = 1) {
    const date = new Date(this);
    date.setHours(date.getHours() - hours);
    return date;
  };

  Date.prototype.subDays = function (days = 1) {
    const date = new Date(this);
    date.setDate(date.getDate() - days);
    return date;
  };

  Date.prototype.subWeeks = function (weeks = 1) {
    const date = new Date(this);
    date.setDate(date.getDate() - (weeks * 7));
    return date;
  };

  Date.prototype.subMonths = function (months = 1) {
    const date = new Date(this);
    date.setMonth(date.getMonth() - months);
    return date;
  };

  Date.prototype.subYears = function (years = 1) {
    const date = new Date(this);
    date.setFullYear(date.getFullYear() - years);
    return date;
  };

  Date.prototype.isStartOfWeek = function () {
    return new Date().startOfDay().getTime() === new Date(this).startOfWeek().getTime();
  };

  Date.prototype.isStartOfMonth = function () {
    return new Date().startOfDay().getTime() === new Date(this).startOfMonth().getTime();
  };

  Date.prototype.isStartOfYear = function () {
    return new Date().startOfDay().getTime() === new Date(this).startOfYear().getTime();
  };
}).call(this);
